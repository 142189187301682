import React, { Fragment } from 'react';
import PropTypes from 'prop-types';
import { compose } from 'recompose';
import { find, propEq } from 'ramda';
import styled, { css } from 'styled-components';
import { ifProp, palette } from 'styled-tools';
import { connect } from 'react-redux';
import { bindActionCreators } from 'redux';
import ContentLoader from 'react-content-loader';

import * as actions from 'actions';

import { requestInProcess } from 'selectors';

import { Link, Paragraph } from 'components/shared';

import * as requestTypes from 'constants/requestTypes';
import { applicationPropTypesRequired } from 'propTypes';
import { getIn, percentFormat, decimalCurrencyFormat } from 'utils';

import { UpdateLTVForm } from 'components/blocks/LeadForm/shared/Qualified/shared';
import { Table, Th, Tr, Td } from 'components/blocks/LeadForm/shared/Qualified/shared/Table';
import BuyPointsSlider from './BuyPointsSlider';
import Loader from './Loader';

const propTypes = {
  ...applicationPropTypesRequired,
  className: PropTypes.string,
  selectQuote: PropTypes.func.isRequired,
  loading: PropTypes.bool,
  selectRequestInProgress: PropTypes.bool,
};

const defaultProps = {
  className: null,
  selectedQuote: {},
};

function Quotes({ application, selectedQuote, className, selectQuote, loading, selectRequestInProgress }) {
  return (
    <Fragment>
      <UpdateLTVForm loading={loading || selectRequestInProgress} />

      <Fragment>
        {Boolean(application.quotes) ? (
          <Fragment>
            <QuotesHeader>Rate Quote prior to Completed Application</QuotesHeader>
            <Table className={className}>
              <thead>
                <Tr>
                  <Th />
                  <Th>Loan Amount</Th>
                  <Th>Interest Rate</Th>
                  <Th>Term</Th>
                  <Th>Origination Fee</Th>
                  <Th>Payment Type</Th>
                  <Th>Monthly Payment</Th>
                  <Th>Final Rate</Th>
                  <Th>Buy Up/Down Rate</Th>
                  <Th>Buy Down Fee</Th>
                  <Th />
                </Tr>
              </thead>
              <tbody>
                {application.quotes &&
                  application.quotes.map((quote, index) => (
                    <Tr selected={quote.selected} key={`application-${application.id}-quote-${index}`}>
                      {/* Product Name */}
                      <Td>{quote.product.name}</Td>

                      {/* Loan Amount */}
                      <Td right bold>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          decimalCurrencyFormat(quote.totalAmount)
                        )}
                      </Td>

                      {/* Interest Rate */}
                      <Td right bold>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          percentFormat(getIn('interestRates.calculatedInterestRate', quote))
                        )}
                      </Td>

                      {/* Term */}
                      <Td right bold>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          quote.term
                        )}
                      </Td>

                      {/* Origination Fee */}
                      <Td right bold>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          percentFormat(getIn('originationFeePercents.calculatedOriginationFeePercent', quote))
                        )}
                      </Td>

                      {/* Payment Type  */}
                      <Td>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          getIn('paymentType', quote)
                        )}
                      </Td>

                      {/* Monthly Payment  */}
                      <Td>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          decimalCurrencyFormat(quote.monthlyPayment)
                        )}
                      </Td>

                      {/* Final Rate */}
                      <Td right highlight>
                        {loading ? (
                          <ContentLoaderHighlighted />
                        ) : (
                          percentFormat(getIn('interestRates.finalBuyPointsInterestRate', quote))
                        )}
                      </Td>

                      {/* Buy Up/Down Rate */}
                      <Td right>
                        {loading ? (
                          quote.selected ? <ContentLoaderHighlighted /> : <ContentLoaderDefault />
                        ) : (
                          percentFormat(quote.buyUpDownRate)
                        )}
                      </Td>

                      {/* Buy Down Fee */}
                      <Td right highlight>
                        {loading ? (
                          <ContentLoaderHighlighted />
                        ) : (
                          percentFormat(
                            getIn('boughtFee', quote),
                          )
                        )}
                      </Td>

                      {/* Select */}
                      <Td right>
                        {quote.selected ? (
                          <span>
                            {loading ? "Loading..." : (selectRequestInProgress ? "Selecting" : "Selected")}
                          </span>
                        ) : (
                          <LinkStyled
                            onClick={() =>
                              selectQuote({
                                token: application.token,
                                quote: { ...quote, productId: getIn('product.id', quote) },
                              })
                            }
                            disabled={loading || selectRequestInProgress}
                          >
                            {loading ? "Loading..." : "Select"}
                          </LinkStyled>
                        )}
                      </Td>
                    </Tr>
                  ))}
              </tbody>
            </Table>
          </Fragment>
        ) : (
          <Loader />
        )}

        {application.quotes && selectedQuote.buyPointsViewable && (
          <SliderWrapper>
            <Paragraph style={{ marginBottom: 20 }} center>
              Buy Points
            </Paragraph>

            <BuyPointsSlider application={application} loading={loading || selectRequestInProgress} />
          </SliderWrapper>
        )}
      </Fragment>
    </Fragment>
  );
}

const QuotesHeader = styled.div`
  text-align: center;
  font-weight: 700;
  padding-bottom: 5px;
  border-bottom: 1px solid #d5dde9;
`;

const SliderWrapper = styled.div`
  margin: 20px auto 80px;
  max-width: 820px;
`;

const LinkStyled = styled(Link)`
  ${ifProp(
    'disabled',
    css`
    color: ${palette('grayscale', 3)};
    `,
  )};
`;

const ContentLoaderHighlighted = () => <ContentLoader speed={3} primaryColor="#e3f2fd" secondaryColor="#bbdefb" />
const ContentLoaderDefault = () => <ContentLoader speed={3} />

Quotes.propTypes = propTypes;
Quotes.defaultProps = defaultProps;

const mapStateToProps = (state, props) => {
  const { application } = props;
  return {
    selectedQuote: application.quotes && find(propEq('selected', true), application.quotes),
  };
};

function mapDispatchToProps(dispatch) {
  return {
    selectQuote: bindActionCreators(actions.selectQuote, dispatch),
    updateLTV: bindActionCreators(actions.discussQuote, dispatch),
  };
}

const enhance = compose(
  connect(
    mapStateToProps,
    mapDispatchToProps,
  ),
);

export default enhance(Quotes);
